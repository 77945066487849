import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {loraDataRateToValue, chooseDailyDose, chooseInterImpulseTime} from "./common";
import RichTable from "./RichTable";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    }
}));

export default function DeviceCalibrations({device, deviceStatuses}) {
    const classes = useStyles();

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <RichTable name="Kalibracje" columns={
                        [
                            {id: 'time', numeric: false, disablePadding: false, label: 'Czas'},
                            {id: 'recvTime', numeric: false, disablePadding: false, label: 'Czas odbioru'},
                            {id: 'seqNo', numeric: true, disablePadding: false, label: 'Numer sek.'},
                            {
                                id: 'meter1DailyDose',
                                numeric: true,
                                disablePadding: false,
                                label: 'Dzienna dawka ZW (m3)'
                            },
                            {
                                id: 'meter2DailyDose',
                                numeric: true,
                                disablePadding: false,
                                label: 'Dzienna dawka CW (m3)'
                            },
                            {
                                id: 'meter1InterImpulseTime',
                                numeric: true,
                                disablePadding: false,
                                label: 'Czas międzyimpulsowy ZW (s)'
                            },
                            {
                                id: 'meter2InterImpulseTime',
                                numeric: true,
                                disablePadding: false,
                                label: 'Czas międzyimpulsowy CW (s)'
                            },
                            {id: 'loraDr', numeric: true, disablePadding: false, label: 'LoRa DR'},
                            {id: 'gwId', numeric: false, disablePadding: false, label: 'ID bramki'},
                            {id: 'rssi', numeric: true, disablePadding: false, label: 'RSSI (dBm)'},
                            {id: 'snr', numeric: true, disablePadding: false, label: 'SNR (dB)'}
                        ]
                    } initialRowsById={new Map(deviceStatuses.filter(value => value.isCalibration).map(status => {
                        const id = moment(status.time).format("YYYY-MM-DD-HH-mm-ss");
                        return [id, {
                            id: id,
                            time: moment(status.time).format("YYYY-MM-DD HH:mm:ss"),
                            recvTime: moment(status.rxMetadata.time).format("YYYY-MM-DD HH:mm:ss"),
                            seqNo: status.seqNo,
                            meter1DailyDose: chooseDailyDose(device, status.time, status.meter1DailyDose, status.meter2DailyDose, "COLD"),
                            meter2DailyDose: chooseDailyDose(device, status.time, status.meter1DailyDose, status.meter2DailyDose, "HOT"),
                            meter1InterImpulseTime: chooseInterImpulseTime(device, status.time, status.meter1InterImpulseTime, status.meter2InterImpulseTime, "COLD"),
                            meter2InterImpulseTime: chooseInterImpulseTime(device, status.time, status.meter1InterImpulseTime, status.meter2InterImpulseTime, "HOT"),
                            loraDr: loraDataRateToValue(status.rxMetadata.dataRate),
                            gwId: status.rxMetadata.gwInfo[0].gwId,
                            rssi: status.rxMetadata.gwInfo[0].rssi,
                            snr: status.rxMetadata.gwInfo[0].snr,
                        }];
                    }))} initialOrderBy="time" initialOrder="desc"/>
                </Grid>
            </Grid>
        </Container>
    );
}