import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {loraDataRateToValue, chooseDailyDose, chooseInterImpulseTime} from "./common";
import RichTable from "./RichTable";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: "none",
    }
}));

export default function GroupCalibrations({organizationId, groupId, since, until, devicesByDeviceId, deviceStatuses}) {
    const classes = useStyles();

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <RichTable name="Kalibracje" columns={
                        [
                            {id: 'deviceName', numeric: false, disablePadding: false, label: 'Nazwa urządzenia', render: (row) => (<Link className={classes.link} to={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/" + row.deviceId + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.deviceName}</Link>)},
                            {id: 'time', numeric: false, disablePadding: false, label: 'Czas'},
                            {id: 'recvTime', numeric: false, disablePadding: false, label: 'Czas odbioru'},
                            {id: 'seqNo', numeric: true, disablePadding: false, label: 'Numer sek.'},
                            {id: 'deviceCollectorId', numeric: false, disablePadding: false, label: 'ID inkasenta'},
                            {id: 'deviceClientId', numeric: false, disablePadding: false, label: 'ID klienta'},
                            {id: 'deviceClientAddress', numeric: false, disablePadding: false, label: 'Adres klienta'},
                            {id: 'meter1DailyDose', numeric: true, disablePadding: false, label: 'Dzienna dawka ZW (m3)'},
                            {id: 'meter2DailyDose', numeric: true, disablePadding: false, label: 'Dzienna dawka CW (m3)'},
                            {id: 'meter1InterImpulseTime', numeric: true, disablePadding: false, label: 'Czas międzyimpulsowy ZW (s)'},
                            {id: 'meter2InterImpulseTime', numeric: true, disablePadding: false, label: 'Czas międzyimpulsowy CW (s)'},
                            {id: 'loraDr', numeric: true, disablePadding: false, label: 'LoRa DR'},
                            {id: 'gwId', numeric: false, disablePadding: false, label: 'ID bramki'},
                            {id: 'rssi', numeric: true, disablePadding: false, label: 'RSSI (dBm)'},
                            {id: 'snr', numeric: true, disablePadding: false, label: 'SNR (dB)'}
                        ]
                    } initialRowsById={new Map(deviceStatuses.filter(value => value.isCalibration).map(status => {
                        const device = devicesByDeviceId.get(status.deviceId);
                        const id = device.id + "-" + moment(status.time).format("YYYY-MM-DD-HH-mm-ss");
                        return [id, {
                            id: id,
                            deviceId: status.deviceId,
                            deviceName: device.name,
                            time: moment(status.time).format("YYYY-MM-DD HH:mm:ss"),
                            recvTime: moment(status.rxMetadata.time).format("YYYY-MM-DD HH:mm:ss"),
                            seqNo: status.seqNo,
                            deviceCollectorId: device.collectorId,
                            deviceClientId: device.clientId,
                            deviceClientAddress: device.clientAddress,
                            meter1DailyDose: chooseDailyDose(device, status.time, status.meter1DailyDose, status.meter2DailyDose, "COLD"),
                            meter2DailyDose: chooseDailyDose(device, status.time, status.meter1DailyDose, status.meter2DailyDose, "HOT"),
                            meter1InterImpulseTime: chooseInterImpulseTime(device, status.time, status.meter1InterImpulseTime, status.meter2InterImpulseTime, "COLD"),
                            meter2InterImpulseTime: chooseInterImpulseTime(device, status.time, status.meter1InterImpulseTime, status.meter2InterImpulseTime, "HOT"),
                            loraDr: loraDataRateToValue(status.rxMetadata.dataRate),
                            gwId: status.rxMetadata.gwInfo[0].gwId,
                            rssi: status.rxMetadata.gwInfo[0].rssi,
                            snr: status.rxMetadata.gwInfo[0].snr,
                        }];
                    }))} initialOrderBy="time" initialOrder="desc" />
                </Grid>
            </Grid>
        </Container>
    );
}