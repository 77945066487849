import React from 'react';
import TextField from "@material-ui/core/TextField";
import Form from "./Form";
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    TimePicker
} from '@material-ui/pickers';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    label: {
        fontSize: "1rem"
    },
}));

export default function GroupForm({edit, initialGroup, onSubmit, admin}) {
    const classes = useStyles();
    const [group, setGroup] = React.useState(initialGroup);

    function onFormSubmit(event) {
        onSubmit(group);
        event.preventDefault();
    }

    function onChange(updateState) {
        setGroup(prevState => {
            const prevStateCloned = Object.assign({}, prevState);
            return updateState(prevStateCloned);
        });
    }

    return <Form title="Instalacja" onSubmit={onFormSubmit} submitButtonLabel={edit ? "Zapisz" : "Dodaj"} admin={admin}>
        <TextField
            name="name"
            label="Nazwa"
            margin="normal"
            value={group.name}
            onChange={event => {
                const value = event.target.value;
                onChange(group => {
                    group.name = value;
                    return group;
                });
            }}
            inputProps={{
                pattern: "[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 -_]+",
            }}
            fullWidth
            required
            disabled={!admin}
        />
        {/*<Typography variant="button" display="block" className={classes.header}>*/}
        {/*    Powiadomienia*/}
        {/*</Typography>*/}
        {/*<MuiPickersUtilsProvider utils={MomentUtils}>*/}
        {/*    <Grid container spacing={3}>*/}
        {/*        <Grid item xs={12} md={6} lg={6}>*/}
        {/*            <TimePicker*/}
        {/*                name="notificationTimeRange.from"*/}
        {/*                value={new Date("1970-01-01T" + group.notificationTimeRange.from)}*/}
        {/*                onChange={value => {*/}
        {/*                    setGroup(prevState => {*/}
        {/*                        const updateState = Object.assign({}, prevState);*/}
        {/*                        updateState.notificationTimeRange.from = value.format("HH:mm:ss");*/}
        {/*                        return updateState;*/}
        {/*                    });*/}
        {/*                }}*/}
        {/*                views={["hours"]}*/}
        {/*                label="Od"*/}
        {/*                margin="normal"*/}
        {/*                ampm={false}*/}
        {/*                invalidDateMessage={"Nieprawidłowy format"}*/}
        {/*                cancelLabel="Anuluj"*/}
        {/*                okLabel="Ustaw"*/}
        {/*                format="HH:mm:ss"*/}
        {/*                disabled={!admin}*/}
        {/*            />*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={12} md={6} lg={6}>*/}
        {/*            <TimePicker*/}
        {/*                name="notificationTimeRange.to"*/}
        {/*                value={new Date("1970-01-01T" + group.notificationTimeRange.to)}*/}
        {/*                onChange={value => {*/}
        {/*                    setGroup(prevState => {*/}
        {/*                        const updateState = Object.assign({}, prevState);*/}
        {/*                        updateState.notificationTimeRange.to = value.format("HH:mm:ss");*/}
        {/*                        return updateState;*/}
        {/*                    });*/}
        {/*                }}*/}
        {/*                views={["hours"]}*/}
        {/*                label="Do"*/}
        {/*                margin="normal"*/}
        {/*                ampm={false}*/}
        {/*                invalidDateMessage={"Nieprawidłowy format"}*/}
        {/*                cancelLabel="Anuluj"*/}
        {/*                okLabel="Ustaw"*/}
        {/*                format="HH:mm:ss"*/}
        {/*                disabled={!admin}*/}
        {/*            />*/}
        {/*        </Grid>*/}
        {/*    </Grid>*/}
        {/*</MuiPickersUtilsProvider>*/}
    </Form>;
}