import React from 'react';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import moment from "moment";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";

export default function TimedValueForecastPanel({title, unit, color, timedValue, forecast}) {
  return (
    <React.Fragment>
      <Title>{title}</Title>
        <div style={{display:"flex"}}>
  <Typography variant="h4" style={{
      color: (timedValue.value || timedValue.value === 0) ? color : "black"
  }}>{(timedValue.value === 1/0 ? "∞" : (timedValue.value || timedValue.value === 0) ? timedValue.value + unit: <Tooltip title="Brak danych lub błędne ustawienia"><WarningIcon style={{color: "red"}}/></Tooltip>) }</Typography>
            <Typography variant="h4" color="textSecondary" style={{
                marginLeft: 20,
            }}>{forecast >= 0 ? "+" + forecast: forecast} za 30 min</Typography>
        </div>
        <Typography color="textSecondary" style={{
          fontSize: "0.875rem",
          marginTop: 5
      }}>{timedValue.time ? moment(timedValue.time).format("L LTS") : ""}</Typography>
    </React.Fragment>
  );
}