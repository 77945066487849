import React from "react";

export function GoogleMap({id, className, apiKey, options, onMapLoad, onMapUnload = () => {}, children}) {
    let map = null;

    React.useEffect(() => {
        if(!window.google) {
            const mapScriptElem = document.createElement("script");
            mapScriptElem.type = "text/javascript";
            mapScriptElem.src = "https://maps.google.com/maps/api/js?key=" + apiKey;
            const firstScriptElem = document.getElementsByTagName("script")[0];
            firstScriptElem.parentNode.insertBefore(mapScriptElem, firstScriptElem);
            mapScriptElem.addEventListener("load", e => {
                createMap();
                onMapLoad(map);
            });
        } else {
            createMap();
            onMapLoad(map);
        }

        return onMapUnload;
    }, []);

    function createMap() {
        map = map ? map : new window.google.maps.Map(document.getElementById(id), options);
        let markers = React.Children.map(children,child => {
            let marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(child.props.lat, child.props.long),
                draggable: false,
                icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillColor: "black",
                    fillOpacity: 1,
                    scale: 8,
                    strokeColor: "black",
                    strokeWeight: 0
                }
            });
            marker.setMap(map);
            return marker;
        });

        let bounds = new window.google.maps.LatLngBounds();
        markers.forEach(marker => {
            bounds.extend(marker.getPosition());
        })
        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds);
        map.setZoom(map.getZoom() - 1);
        if(map.getZoom() > 15){
            map.setZoom(15);
        }
    }

    /*function createMarker(lat, long, zIndex, icon) {
        return new Marker({
            position: new LatLng(lat, long),
            draggable: false,
            zIndex: zIndex,
            icon: icon
        });
    }

    function addMarker(lat, long, icon, zIndex, onMouseOver, onClick) {
        const marker = new Marker({
            position: new LatLng(lat, long),
            draggable: false,
            zIndex: zIndex,
            icon: icon
        });

        event.addListener(marker, "mouseover", function() {
            //clickInfoWindow.close();
            //clickInfoWindow.setContent(descriptions[id]);

            //clickInfoWindow.open(map, this);
        });
    }*/

    return <div id={id} className={className} />;
}

export function Marker({lat, long, zIndex, icon, time}) {
}