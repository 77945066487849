import TimedValue from "./TimedValue";
import {useLocation} from "react-router-dom";
import {fetch} from "whatwg-fetch";

export function pairwise(arr) {
    return arr.reduce(function (result, value, index, array) {
        if (index % 2 === 0)
            result.push(array.slice(index, index + 2));
        return result;
    }, []);
}

/*export function calculateActive(device, status, waterType) {
    let active = false;

    if (status && waterType && device) {
        if (device.meter1WaterType === waterType && status.meter1Index) {
            active = true;
        } else if (device.meter2WaterType === waterType && status.meter2Index) {
            active = true;
        }
    }

    return active;
}*/

export function calculateState(device, status, waterType) {
    let time1 = null;
    let time2 = null;
    let state1 = null;
    let state2 = null;

    if (status && waterType && device) {
        if (device.meter1WaterType === waterType && status.meter1Index) {
            time1 = status.meter1Index.time;
            state1 = (status.meter1Index.value * device.meter1Multiplier + device.meter1InitialIndex * device.meter1Multiplier) / 1000;
        }

        if (device.meter2WaterType === waterType && status.meter2Index) {
            time2 = status.meter2Index.time;
            state2 = (status.meter2Index.value * device.meter2Multiplier + device.meter2InitialIndex * device.meter2Multiplier) / 1000;
        }
    }

    let stateSum = state1 + state2;

    return new TimedValue((time1 !== null) ? time1 : time2, (stateSum !== null) ? stateSum.toFixed(3) : null);
}

export function calculateUsage(device, startStatus, endStatus, waterType) {
    let time1 = null;
    let time2 = null;

    let usage1 = null;
    let usage2 = null;

    if (startStatus && endStatus && waterType && device) {
        if (device.meter1WaterType === waterType && startStatus.meter1Index && endStatus.meter1Index) {
            time1 = endStatus.meter1Index.time;
            usage1 = (endStatus.meter1Index.value * device.meter1Multiplier - startStatus.meter1Index.value * device.meter1Multiplier) / 1000;
        }

        if (device.meter2WaterType === waterType && startStatus.meter2Index && endStatus.meter2Index) {
            time2 = endStatus.meter2Index.time;
            usage2 = (endStatus.meter2Index.value * device.meter2Multiplier - startStatus.meter2Index.value * device.meter2Multiplier) / 1000;
        }
    }

    let usageSum = usage1 + usage2;

    return new TimedValue((time1 !== null) ? time1 : time2, (usageSum !== null) ? usageSum.toFixed(3) : null);
}

export function calculateTime(groupDeviceStatuses) {
    const a = Array.from(groupDeviceStatuses.values())
        .map(value => value.length > 0 ? value[0] : null)
        .sort((a, b) => b.time - a.time)[0];
    return a ? a.time : null;
}

export function calculateAlarms3(devicesById, alarmDeviceStatusesByDeviceId, waterType) {
    let emptyAlarms = {
        meterConnected: [],
        meterDisconnected: [],
        meterDoseExceeded: [],
        meterHoseCracked: [],
        meterLeak: [],
        valveLeak: [],
        valveOpened: [],
        valveClosed: [],
        batteryLow: []
    };

    for (let [key, value] of alarmDeviceStatusesByDeviceId) {
        if (value) {
            value.filter(status => status.isAlarm).forEach(status => {
                const alarms = calculateAlarms(devicesById.get(key), status, waterType);

                if (alarms.meterConnected) {
                    emptyAlarms.meterConnected.push(alarms.meterConnected);
                }

                if (alarms.meterDisconnected) {
                    emptyAlarms.meterDisconnected.push(alarms.meterDisconnected);
                }

                if (alarms.meterDoseExceeded) {
                    emptyAlarms.meterDoseExceeded.push(alarms.meterDoseExceeded);
                }

                if (alarms.meterHoseCracked) {
                    emptyAlarms.meterHoseCracked.push(alarms.meterHoseCracked);
                }

                if (alarms.meterLeak) {
                    emptyAlarms.meterLeak.push(alarms.meterLeak);
                }

                if (alarms.valveLeak) {
                    emptyAlarms.valveLeak.push(alarms.valveLeak);
                }

                if (alarms.valveOpened) {
                    emptyAlarms.valveOpened.push(alarms.valveOpened);
                }

                if (alarms.valveClosed) {
                    emptyAlarms.valveClosed.push(alarms.valveClosed);
                }

                if (alarms.batteryLow) {
                    emptyAlarms.batteryLow.push(alarms.batteryLow);
                }
            });
        }
    }

    return {
        meterConnected: emptyAlarms.meterConnected.length > 0 ? emptyAlarms.meterConnected.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        meterDisconnected: emptyAlarms.meterDisconnected.length > 0 ? emptyAlarms.meterDisconnected.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        meterDoseExceeded: emptyAlarms.meterDoseExceeded.length > 0 ? emptyAlarms.meterDoseExceeded.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        meterHoseCracked: emptyAlarms.meterHoseCracked.length > 0 ? emptyAlarms.meterHoseCracked.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        meterLeak: emptyAlarms.meterLeak.length > 0 ? emptyAlarms.meterLeak.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        valveLeak: emptyAlarms.valveLeak.length > 0 ? emptyAlarms.valveLeak.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        valveOpened: emptyAlarms.valveOpened.length > 0 ? emptyAlarms.valveOpened.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        valveClosed: emptyAlarms.valveClosed.length > 0 ? emptyAlarms.valveClosed.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        batteryLow: emptyAlarms.batteryLow.length > 0 ? emptyAlarms.batteryLow.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null
    };
}

export function calculateAlarms2(device, deviceStatuses, waterType) {
    let emptyAlarms = {
        meterConnected: [],
        meterDisconnected: [],
        meterDoseExceeded: [],
        meterHoseCracked: [],
        meterLeak: [],
        valveLeak: [],
        valveOpened: [],
        valveClosed: [],
        batteryLow: []
    };

    deviceStatuses.filter(status => status.isAlarm).forEach(status => {
        const alarms = calculateAlarms(device, status, waterType);

        if (alarms.meterConnected) {
            emptyAlarms.meterConnected.push(alarms.meterConnected);
        }

        if (alarms.meterDisconnected) {
            emptyAlarms.meterDisconnected.push(alarms.meterDisconnected);
        }

        if (alarms.meterDoseExceeded) {
            emptyAlarms.meterDoseExceeded.push(alarms.meterDoseExceeded);
        }

        if (alarms.meterHoseCracked) {
            emptyAlarms.meterHoseCracked.push(alarms.meterHoseCracked);
        }

        if (alarms.meterLeak) {
            emptyAlarms.meterLeak.push(alarms.meterLeak);
        }

        if (alarms.valveLeak) {
            emptyAlarms.valveLeak.push(alarms.valveLeak);
        }

        if (alarms.valveOpened) {
            emptyAlarms.valveOpened.push(alarms.valveOpened);
        }

        if (alarms.valveClosed) {
            emptyAlarms.valveClosed.push(alarms.valveClosed);
        }

        if (alarms.batteryLow) {
            emptyAlarms.batteryLow.push(alarms.batteryLow);
        }
    });

    return {
        meterConnected: emptyAlarms.meterConnected.length > 0 ? emptyAlarms.meterConnected.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        meterDisconnected: emptyAlarms.meterDisconnected.length > 0 ? emptyAlarms.meterDisconnected.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        meterDoseExceeded: emptyAlarms.meterDoseExceeded.length > 0 ? emptyAlarms.meterDoseExceeded.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        meterHoseCracked: emptyAlarms.meterHoseCracked.length > 0 ? emptyAlarms.meterHoseCracked.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        meterLeak: emptyAlarms.meterLeak.length > 0 ? emptyAlarms.meterLeak.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        valveLeak: emptyAlarms.valveLeak.length > 0 ? emptyAlarms.valveLeak.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        valveOpened: emptyAlarms.valveOpened.length > 0 ? emptyAlarms.valveOpened.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        valveClosed: emptyAlarms.valveClosed.length > 0 ? emptyAlarms.valveClosed.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null,
        batteryLow: emptyAlarms.batteryLow.length > 0 ? emptyAlarms.batteryLow.sort((a, b) => b.time.getTime() - a.time.getTime())[0] : null
    };
}

export function calculateAlarms(device, status, waterType) {
    if (status && waterType) {
        let alarms = {
            meterConnected: null,
            meterDisconnected: null,
            meterDoseExceeded: null,
            meterHoseCracked: null,
            meterLeak: null,
            valveLeak: null,
            valveOpened: null,
            valveClosed: null,
            batteryLow: null
        };

        if (device && device.meter1WaterType === waterType) {
            alarms = {
                meterConnected: status.meter1Connected,
                meterDisconnected: status.meter1Disconnected,
                meterDoseExceeded: status.meter1DoseExceeded,
                meterHoseCracked: status.meter1HoseCracked,
                meterLeak: status.meter1Leak,
                valveLeak: status.valve1Leak,
                valveOpened: status.valve1Opened,
                valveClosed: status.valve1Closed,
                batteryLow: status.batteryLow
            };
        } else if (device && device.meter2WaterType === waterType) {
            alarms = {
                meterConnected: status.meter2Connected,
                meterDisconnected: status.meter2Disconnected,
                meterDoseExceeded: status.meter2DoseExceeded,
                meterHoseCracked: status.meter2HoseCracked,
                meterLeak: status.meter2Leak,
                valveLeak: status.valve2Leak,
                valveOpened: status.valve2Opened,
                valveClosed: status.valve2Closed,
                batteryLow: status.batteryLow
            };
        }

        return alarms;
    } else {
        return {
            meterConnected: null,
            meterDisconnected: null,
            meterDoseExceeded: null,
            meterHoseCracked: null,
            meterLeak: null,
            valveLeak: null,
            valveOpened: null,
            valveClosed: null,
            batteryLow: null
        };
    }
}

export function loraDataRateToValue(dataRate) {
    let value = null;
    switch (dataRate) {
        case "LORA_DR0":
            value = 0;
            break;
        case "LORA_DR1":
            value = 1;
            break;
        case "LORA_DR2":
            value = 2;
            break;
        case "LORA_DR3":
            value = 3;
            break;
        case "LORA_DR4":
            value = 4;
            break;
        case "LORA_DR5":
            value = 5;
            break;
        case "LORA_DR6":
            value = 6;
            break;
        case "LORA_DR7":
            value = 7;
            break;
        case "LORA_DR8":
            value = 8;
            break;
        case "LORA_DR9":
            value = 9;
            break;
        case "LORA_DR10":
            value = 10;
            break;
        case "LORA_DR11":
            value = 11;
            break;
        case "LORA_DR12":
            value = 12;
            break;
        case "LORA_DR13":
            value = 13;
            break;
        case "LORA_DR14":
            value = 14;
            break;
        case "LORA_DR15":
            value = 15;
            break;
        default:
            value = null;
    }
    return value;
}

export function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export function avg(values) {
    const total = values.reduce((acc, c) => acc + c, 0);
    return total / values.length;
}

export function sum(value) {
    const total = value.reduce((acc, c) => acc + c, 0);
    return total;
}

export function roundTimeToMinutes(date, minutes) {
    const p = minutes * 60 * 1000; // milliseconds in an hour
    return new Date(Math.round(date.getTime() / p) * p);
}

export function truncateTimeToHour(date) {
    let updatedDate = new Date(date.getTime());
    updatedDate.setMinutes(0);
    updatedDate.setSeconds(0);
    updatedDate.setMilliseconds(0);
    return updatedDate;
}

export function truncateTimeToDay(date) {
    let updatedDate = new Date(date.getTime());
    updatedDate.setHours(0);
    updatedDate.setMinutes(0);
    updatedDate.setSeconds(0);
    updatedDate.setMilliseconds(0);
    return updatedDate;
}

export function round(num) {
    return num ? Math.round(num * 100) / 100 : null;
}

export function useURLSearchParams() {
    return new URLSearchParams(useLocation().search);
}

export function uuidv4() {
    return (`${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function calculateBalanceColor(balance) {
    let balanceColor = "green";
    if (balance >= 10 || balance <= -10) {
        balanceColor = "red";
    } else if (balance >= 5 || balance <= -5) {
        balanceColor = "orange";
    }
    return balanceColor;
}

export function chooseAlarm(device, time, alarm1, alarm2, waterType, notification) {
    if (device.meter1WaterType === waterType) {
        return alarm1 && alarm1.time.getTime() === time.getTime() ? (alarm1.value === true ? notification : null) : null;
    }
    if (device.meter2WaterType === waterType) {
        return alarm2 && alarm2.time.getTime() === time.getTime() ? (alarm2.value === true ? notification : null) : null;
    }
    return null;
}

export function chooseDailyDose(device, time, alarm1, alarm2, waterType) {
    if (device.meter1WaterType === waterType) {
        return alarm1 && alarm1.time.getTime() === time.getTime() ? alarm1.value / 1000 : null;
    }
    if (device.meter2WaterType === waterType) {
        return alarm2 && alarm2.time.getTime() === time.getTime() ? alarm2.value / 1000 : null;
    }
    return null;
}

export function chooseInterImpulseTime(device, time, alarm1, alarm2, waterType) {
    if (device.meter1WaterType === waterType) {
        return alarm1 && alarm1.time.getTime() === time.getTime() ? alarm1.value.seconds() : null;
    }
    if (device.meter2WaterType === waterType) {
        return alarm2 && alarm2.time.getTime() === time.getTime() ? alarm2.value.seconds() : null;
    }
    return null;
}

export function stripTrailingSlash(str) {
    if (str.substr(-1) === '/') {
        return str.substr(0, str.length - 1);
    }
    return str;
}

export function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function authorize() {
    const authorizeUrl = "https://authorization.ocupoly.com/oauth2/authorize?response_type=token&client_id=monitoring-ui&redirect_uri=" + stripTrailingSlash(encodeURIComponent(window.location.href)) + "&audience=https://monitoring.ocupoly.com/api/v1";
    window.location.replace(authorizeUrl);
}

export function logout() {

    const authorizeUrl = "https://authorization.ocupoly.com/oauth2/authorize?response_type=token&client_id=monitoring-ui&redirect_uri=" + stripTrailingSlash(encodeURIComponent("localhost:3000")) + "&audience=https://monitoring.ocupoly.com/api/v1";
    //window.location.replace(authorizeUrl);

    console.log(authorizeUrl);

    fetch(authorizeUrl, {
        method: 'GET',
        headers: {
            "Authorization": "Basic "
        }
    }).catch(function(response) {
        return console.log(response);
    })
}

export function randomIntFromInterval(min, max, hardMin, hardMax) { // min and max included
    const a = Math.floor(Math.random() * (max - min + 1) + min);
    if (a < hardMin) {
        return hardMin;
    } else if (a > hardMax) {
        return hardMax;
    } else {
        return a;
    }
}
