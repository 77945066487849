import React from 'react';
import { useParams } from "react-router-dom";
import GroupDashboard from "./GroupDashboard";
import TabLayout from "./TabLayout";
import GroupDevices from "./GroupDevices";
import {groupBy} from "./common";
import GroupReadings from "./GroupReadings";
import GroupAlarms from "./GroupAlarms";
import GroupCalibrations from "./GroupCalibrations";
import GroupSettings from "./GroupSettings";

export default function GroupLayout({ds, since, until, admin, kiosk}) {
    const params = useParams();

    const organizationId = params.organizationId;
    const groupId = params.groupId;

    const [group, setGroup] = React.useState(null);

    React.useEffect(() => {
        ds.retrieveGroup(organizationId, groupId,
            group => setGroup(group))
    }, [organizationId, groupId]);

    function updateGroup(group, callback) {
        ds.updateGroup(organizationId, groupId, group, callback);
    }

    const [devices, setDevices] = React.useState([]);
    const devicesById = new Map(devices.map(value => [value.id, value]));

    React.useEffect(() => {
        ds.retrieveGroupDevices(groupId,
            devices => setDevices(devices.map(value => value)))
    }, [organizationId, groupId]);

    const [groupGroupDevicesAttributes, setGroupGroupDeviceAttributes] = React.useState([]);
    const groupDevicesByDeviceId = new Map(Object.entries(groupBy(groupGroupDevicesAttributes, "deviceId"))
        .map(([deviceId, values]) => [deviceId, values[0]]));

    React.useEffect(() => {
        ds.retrieveGroupGroupDeviceAttributes(groupId,
            groupDevices => setGroupGroupDeviceAttributes(groupDevices.map(value => value)))
    }, [organizationId, groupId]);

    const [deviceStatuses, setDeviceStatuses] = React.useState([]);

    function calculateDeviceStatusesByDeviceId() {
        return new Map(Object.entries(groupBy(deviceStatuses, "deviceId"))
            .map(([deviceId, values]) => [deviceId, values.sort((a, b) => b.time - a.time)]));
    }

    const deviceStatusesByDeviceId = calculateDeviceStatusesByDeviceId();

    React.useEffect(() => {
        const source = ds.receiveGroupDeviceStatuses(
            groupId,
            since,
            until);

        let opening = true;
        source.onMessages(incomingDeviceStatuses => {
            setDeviceStatuses(prevState => {
                let updated = opening ? [] : prevState.slice();
                opening = false;
                incomingDeviceStatuses.forEach(value => updated.push(value));
                return updated.sort((a, b) => b.time - a.time );
            });
        });

        return function cleanup() {
            source.close();
        };
    }, [organizationId, groupId, since, until]);

    function onDelete(selected) {
        selected.forEach(deviceId =>
            ds.deleteGroupDevice(groupId, deviceId, resp => {
                ds.retrieveGroupDevices(groupId,
                    devices => setDevices(devices.map(value => value)))
            })
        )

    }

    function onFileSelected() {

    }

    const content = kiosk ?
        (<GroupDashboard organizationId={organizationId} groupId={groupId} since={since} until={until} devicesById={devicesById} groupGroupDeviceAttributesByDeviceId={groupDevicesByDeviceId} deviceStatusesByDeviceId={deviceStatusesByDeviceId} deviceStatuses={deviceStatuses} kiosk={kiosk}/>)
        : (
            <TabLayout tabs={
                [
                    {label: "Tablica", name: "dashboard", children: <GroupDashboard organizationId={organizationId} groupId={groupId} since={since} until={until} devicesById={devicesById} groupGroupDeviceAttributesByDeviceId={groupDevicesByDeviceId} deviceStatusesByDeviceId={deviceStatusesByDeviceId} deviceStatuses={deviceStatuses} kiosk={kiosk}/>},
                    {label: "Urządzenia", name: "devices", children: <GroupDevices organizationId={organizationId} groupId={groupId} since={since} until={until} devices={devicesById} deviceStatuses={deviceStatusesByDeviceId} onDelete={onDelete} admin={admin}/>},
                    //{label: "Odczyty", name: "readings", children: <GroupReadings organizationId={organizationId} groupId={groupId} since={since} until={until} devicesByDeviceId={devicesById} deviceStatuses={deviceStatuses}/>},
                    //{label: "Alarmy", name: "alarms", children: <GroupAlarms organizationId={organizationId} groupId={groupId} since={since} until={until} devicesByDeviceId={devicesById} deviceStatuses={deviceStatuses}/>},
                    {label: "Ustawienia", name: "settings", children: <GroupSettings group={group} onUpdate={updateGroup} admin={admin}/>}
                ]
            }/>
        );

    return content;
}