import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import RichTable from "./RichTable";
import moment from "moment";
import {calculateState, loraDataRateToValue} from "./common";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: "none",
    }
}));

export default function GroupReadings({organizationId, groupId, since, until, devicesByDeviceId, deviceStatuses}) {
    const classes = useStyles();

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <RichTable name="Odczyty" columns={
                        [
                            {id: 'deviceName', numeric: false, disablePadding: false, label: 'Nazwa urządzenia', render: (row) => (<Link className={classes.link} to={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/" + row.deviceId + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.deviceName}</Link>)},
                            {id: 'time', numeric: false, disablePadding: false, label: 'Czas'},
                            {id: 'recvTime', numeric: false, disablePadding: false, label: 'Czas odbioru'},
                            {id: 'seqNo', numeric: true, disablePadding: false, label: 'Numer sek.'},
                            {id: 'temperature', numeric: true, disablePadding: false, label: 'Temperatura (°C)'},
                            {id: 'input1Temperature', numeric: true, disablePadding: false, label: 'Temperatura: wejście 1 (°C)'},
                            {id: 'input2Temperature', numeric: true, disablePadding: false, label: 'Temperatura: wejście 2 (°C)'},
                            {id: 'humidity', numeric: true, disablePadding: false, label: 'Wilgotność (%)'},
                            {id: 'atmosphericPressure', numeric: true, disablePadding: false, label: 'Ciśnienie atmosferyczne (Pa)'},
                            {id: 'totalEntryCount', numeric: true, disablePadding: false, label: 'Całkowita liczba wejść'},
                            {id: 'totalExitCount', numeric: true, disablePadding: false, label: 'Całkowita liczba wyjść'},
                            {id: 'occupancy', numeric: true, disablePadding: false, label: 'Zajętość'},
                            {id: 'totalOpenCount', numeric: true, disablePadding: false, label: 'Całkowita liczba otwarć'},
                            {id: 'totalCloseCount', numeric: true, disablePadding: false, label: 'Całkowita liczba zamknięć'},
                            {id: 'totalWaterLeakCount', numeric: true, disablePadding: false, label: 'Całkowita liczba wycieków'},
                            {id: 'position', numeric: true, disablePadding: false, label: 'Pozycja: dł.,szer. geogr. (°)'},
                            {id: 'altitude', numeric: true, disablePadding: false, label: 'Wysokość n.p.m. (m)'},
                            {id: 'distance', numeric: true, disablePadding: false, label: 'Odległość (m)'},
                            {id: 'batteryVoltage', numeric: true, disablePadding: false, label: 'Napięcie baterii (V)'},
                            {id: 'lorawanDr', numeric: true, disablePadding: false, label: 'LoRaWAN DR'},
                            {id: 'gwId', numeric: false, disablePadding: false, label: 'ID bramki'},
                            {id: 'rssi', numeric: true, disablePadding: false, label: 'RSSI (dBm)'},
                            {id: 'snr', numeric: true, disablePadding: false, label: 'SNR (dB)'}
                        ]
                    } initialRowsById={new Map(deviceStatuses.filter(value => value.isReading).map(status => {
                        const device = devicesByDeviceId.get(status.deviceId);
                        const id = device.id + "-" + moment(status.time).format("YYYY-MM-DD-HH-mm-ss");
                        return [id, {
                            id: id,
                            deviceName: device.name,
                            time: moment(status.time).format("YYYY-MM-DD HH:mm:ss"),
                            recvTime: moment(status.rxMetadata.time).format("YYYY-MM-DD HH:mm:ss"),
                            seqNo: status.seqNo,
                            temperature: status.temperature ? status.temperature.value: null,
                            input1Temperature: status.input1Temperature ? status.input1Temperature.value: null,
                            input2Temperature: status.input2Temperature ? status.input2Temperature.value: null,
                            humidity: status.humidity ? status.humidity.value : null,
                            atmosphericPressure: status.atmosphericPressure ? status.atmosphericPressure.value : null,
                            totalEntryCount: status.totalEntryCount ? status.totalEntryCount.value : null,
                            totalExitCount: status.totalExitCount ? status.totalExitCount.value : null,
                            occupancy: status.occupancy ? status.occupancy.value : null,
                            totalOpenCount: status.totalOpenCount ? status.totalOpenCount.value : null,
                            totalCloseCount: status.totalCloseCount ? status.totalCloseCount.value : null,
                            totalWaterLeakCount: status.totalWaterLeakCount ? status.totalWaterLeakCount.value : null,
                            position: status.position ? status.position.value.lat + "," + status.position.value.long : null,
                            altitude: status.altitude ? status.altitude.value : null,
                            distance: status.distance ? status.distance.value : null,
                            batteryVoltage: status.batteryVoltage && status.batteryVoltage.time.getTime() === status.time.getTime() ? (status.batteryVoltage.value) : null,
                            lorawanDr: loraDataRateToValue(status.rxMetadata.dataRate),
                            gwId: status.rxMetadata.gwInfo[0].gwId,
                            rssi: status.rxMetadata.gwInfo[0].rssi,
                            snr: status.rxMetadata.gwInfo[0].snr
                        }];
                    }))} initialOrderBy="time" initialOrder="desc" downloadEnabled />
                </Grid>
            </Grid>
        </Container>
    );
}