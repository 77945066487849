import Group from "./Group"
import Device from "./Device"
import GroupDeviceAttributes from "./GroupDeviceAttributes"
import DeviceStatus from "./DeviceStatus";
import {fetch} from 'whatwg-fetch'
import {EventSourcePolyfill} from "event-source-polyfill";
import Organization from "./Organization";

export default function (config) {
    function addOrganization(organizationId, organization, callback) {
        fetch(config.baseUri + "/organizations/" + organizationId, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            },
            body: JSON.stringify({data: {type: "organization", id: organizationId, attributes: organization}})
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            if(json.data) {
                callback(Organization.fromJson(json.data.attributes));
            } else callback(organization);
        });
    }

    function updateOrganization(organizationId, organization, callback) {
        fetch(config.baseUri + "/organizations/" + organizationId, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            },
            body: JSON.stringify({data: {type: "organization", id: organizationId, attributes: organization}})
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            if(json.data) {
                callback(Organization.fromJson(json.data.attributes));
            } else callback(organization);
        });
    }

    function deleteOrganization(organizationId, callback) {
        fetch(config.baseUri + "/organizations/" + organizationId, {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            callback();
        });
    }

    function retrieveOrganization(organizationId, groupId, callback) {
        fetch(config.baseUri + "/organizations/" + organizationId, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            callback(Organization.fromJson(json.data.attributes));
        });
    }

    function retrieveOrganizations(callback) {
        fetch(config.baseUri + "/organizations", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            const entries = json.data.map(elem => Organization.fromJson(elem.attributes));
            callback(entries);
        });
    }

    function addGroup(organizationId, groupId, group, callback) {
        fetch(config.baseUri + "/organizations/" + organizationId + "/groups/" + groupId, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            },
            body: JSON.stringify({data: {type: "group", id: groupId, attributes: group}})
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            if(json.data) {
                callback(Group.fromJson(json.data.attributes));
            } else callback(group);
        });
    }

    function updateGroup(organizationId, groupId, group, callback) {
        fetch(config.baseUri + "/organizations/" + organizationId + "/groups/" + groupId, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            },
            body: JSON.stringify({data: {type: "group", id: groupId, attributes: group}})
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            if(json.data) {
                callback(Group.fromJson(json.data.attributes));
            } else callback(group);
        });
    }

    function deleteGroup(organizationId, groupId, callback) {
        fetch(config.baseUri + "/organizations/" + organizationId + "/groups/" + groupId, {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            callback();
        });
    }

    function retrieveGroup(organizationId, groupId, callback) {
        fetch(config.baseUri + "/organizations/" + organizationId + "/groups/" + groupId, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            callback(Group.fromJson(json.data.attributes));
        });
    }

    function retrieveGroups(organizationId, callback) {
        fetch(config.baseUri + "/organizations/" + organizationId + "/groups", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            const entries = json.data.map(elem => Group.fromJson(elem.attributes));
            callback(entries);
        });
    }

    function addGroupDevice(groupId, deviceId, device, callback) {
        fetch(config.baseUri + "/groups/" + groupId + "/devices/" + deviceId, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            },
            body: JSON.stringify({data: {type: "device", id: device.id, attributes: device}})
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            if(json.data) {
                callback(Device.fromJson(json.data.attributes));
            } else callback(device);
        });
    }

    function updateGroupDevice(groupId, deviceId, device, callback) {
        fetch(config.baseUri + "/groups/" + groupId + "/devices/" + deviceId, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            },
            body: JSON.stringify({data: {type: "device", id: deviceId, attributes: device}})
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            if(json.data) {
                callback(Device.fromJson(json.data.attributes));
            } else callback(device);
        });
    }

    function deleteGroupDevice(groupId, deviceId, callback) {
        fetch(config.baseUri + "/groups/" + groupId + "/devices/" + deviceId, {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            callback();
        });
    }

    function retrieveGroupDevice(groupId, deviceId, callback) {
        fetch(config.baseUri + "/groups/" + groupId + "/devices/" + deviceId, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            callback(Device.fromJson(json.data.attributes));
        });
    }

    function retrieveGroupDevices(groupId, callback) {
        return fetch(config.baseUri + "/groups/" + groupId + "/devices", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            const entries = json.data.map(elem => Device.fromJson(elem.attributes));
            callback(entries);
            return entries;
        });
    }

    function retrieveGroupsDevices(groupIds, callback) {
        const promises = groupIds.map(groupId => retrieveGroupDevices(groupId, () => {}).then(entries => [groupId, entries]));
        Promise.all(promises)
            .then(values => new Map(values))
            .then(map => callback(map));
    }

    function updateGroupDeviceAttributes(groupId, deviceId, attributes, callback) {
        fetch(config.baseUri + "/groups/" + groupId + "/devices/" + deviceId + "/attributes", {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            },
            body: JSON.stringify({data: {type: "attributes", id: deviceId, attributes: attributes}})
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            if(json.data) {
                callback(GroupDeviceAttributes.fromJson(json.data.attributes));
            } else callback(attributes);
        });
    }

    function retrieveGroupDeviceAttributes(groupId, deviceId, callback) {
        fetch(config.baseUri + "/groups/" + groupId + "/devices/" + deviceId + "/attributes", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            callback(GroupDeviceAttributes.fromJson(json.data.attributes));
        });
    }

    function retrieveGroupGroupDeviceAttributes(groupId, callback) {
        return fetch(config.baseUri + "/groups/" + groupId + "/devices/attributes", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            const entries = json.data.map(elem => GroupDeviceAttributes.fromJson(elem.attributes));
            callback(entries);
            return entries;
        });
    }

    function retrieveGroupsGroupDeviceAttributes(groupIds, callback) {
        const promises = groupIds.map(groupId => retrieveGroupGroupDeviceAttributes(groupId, () => {}).then(entries => [groupId, entries]));
        Promise.all(promises)
            .then(values => new Map(values))
            .then(map => callback(map));
    }

    function receiveDeviceStatuses(deviceId, since, until, lingerMs = 100) {
        const baseUri = config.baseUri + "/statuses/devices/" + deviceId + "?filter[since]=" + since.toISOString();
        const uri = until ? baseUri + "&filter[until]=" + until.toISOString() : baseUri;

        return {
            close: () => {},
            onMessages: callback => {
                let timeout = null;
                let buffer = [];

                fetch(encodeURI(uri), {
                    method: 'GET',
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem("access-token")
                    }
                }).then(function(response) {
                    return response.json();
                }).then(function(json) {
                    const entries = json.data.map(elem => DeviceStatus.fromJson(elem.attributes));
                    callback(entries);
                    //return entries;
                });
            }
        }

        // const conn = new EventSourcePolyfill(encodeURI(uri), {
        //     headers: {
        //         'Authorization': 'Bearer ' + localStorage.getItem("access-token")
        //     }});
        //
        // return {
        //     close: () => {
        //         try {
        //             conn.close();
        //         }
        //         catch(err) {
        //         }
        //     },
        //     onMessages: callback => {
        //         let timeout = null;
        //         let buffer = [];
        //
        //         conn.onmessage = result => {
        //             if (timeout) {
        //                 window.clearTimeout(timeout);
        //             }
        //             if(result.data !== "") {
        //                 const json = JSON.parse(result.data);
        //                 const deviceStatus = DeviceStatus.fromJson(json);
        //                 buffer.push(deviceStatus);
        //                 timeout = window.setTimeout(() => {
        //                     callback(buffer);
        //                     buffer = [];
        //                 }, lingerMs);
        //             }
        //         }
        //
        //         conn.onerror = error => {
        //             if(until < new Date()) conn.close();
        //         }
        //     }
        // }
    }

    function receiveGroupDeviceStatuses(groupId, since, until, lingerMs = 100) {
        const baseUri = config.baseUri + "/statuses/groups/" + groupId + "?filter[since]=" + since.toISOString();
        const uri = until ? baseUri + "&filter[until]=" + until.toISOString() : baseUri;

        return {
            close: () => {},
            onMessages: callback => {
                let timeout = null;
                let buffer = [];

                fetch(encodeURI(uri), {
                    method: 'GET',
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem("access-token")
                    }
                }).then(function(response) {
                    return response.json();
                }).then(function(json) {
                    const entries = json.data.map(elem => DeviceStatus.fromJson(elem.attributes));
                    callback(entries);
                    //return entries;
                });
            }
        }

        // const conn = new EventSourcePolyfill(encodeURI(uri), {
        //     headers: {
        //         'Authorization': 'Bearer ' + localStorage.getItem("access-token")
        //     }});
        //
        // return {
        //     close: () => {
        //         try {
        //             conn.close();
        //         }
        //         catch(err) {
        //         }
        //     },
        //     onMessages: callback => {
        //         let timeout = null;
        //         let buffer = [];
        //
        //         conn.onmessage = result => {
        //             if (timeout) {
        //                 window.clearTimeout(timeout);
        //             }
        //             if(result.data !== "") {
        //                 const json = JSON.parse(result.data);
        //                 const deviceStatus = DeviceStatus.fromJson(json);
        //                 buffer.push(deviceStatus);
        //                 timeout = window.setTimeout(() => {
        //                     callback(buffer);
        //                     buffer = [];
        //                 }, lingerMs);
        //             }
        //         }
        //
        //         conn.onerror = error => {
        //             if(until < new Date()) conn.close();
        //         }
        //     }
        // }
    }

    function receiveOrganizationDeviceStatuses(organizationId, since, until, lingerMs = 100) {
        const baseUri = config.baseUri + "/statuses/organizations/" + organizationId + "?filter[since]=" + since.toISOString();
        const uri = until ? baseUri + "&filter[until]=" + until.toISOString() : baseUri;

        return {
            close: () => {},
            onMessages: callback => {
                let timeout = null;
                let buffer = [];

                fetch(encodeURI(uri), {
                    method: 'GET',
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem("access-token")
                    }
                }).then(function(response) {
                    return response.json();
                }).then(function(json) {
                    const entries = json.data.map(elem => DeviceStatus.fromJson(elem.attributes));
                    callback(entries);
                    //return entries;
                });
            }
        }

        // const conn = new EventSourcePolyfill(encodeURI(uri), {
        //     headers: {
        //         'Authorization': 'Bearer ' + localStorage.getItem("access-token")
        //     }});
        //
        // return {
        //     close: () => {
        //         try {
        //             conn.close();
        //         }
        //         catch(err) {
        //         }
        //     },
        //     onMessages: callback => {
        //         let timeout = null;
        //         let buffer = [];
        //
        //         conn.onmessage = result => {
        //             if (timeout) {
        //                 window.clearTimeout(timeout);
        //             }
        //             if(result.data !== "") {
        //                 const json = JSON.parse(result.data);
        //                 const deviceStatus = DeviceStatus.fromJson(json);
        //                 buffer.push(deviceStatus);
        //                 timeout = window.setTimeout(() => {
        //                     callback(buffer);
        //                     buffer = [];
        //                 }, lingerMs);
        //             }
        //         }
        //
        //         conn.onerror = error => {
        //             if(until < new Date()) conn.close();
        //         }
        //     }
        // }
    }

    return {
        addOrganization: addOrganization,
        updateOrganization: updateOrganization,
        deleteOrganization: deleteOrganization,
        retrieveOrganization: retrieveOrganization,
        retrieveOrganizations: retrieveOrganizations,

        addGroup: addGroup,
        updateGroup: updateGroup,
        retrieveGroup: retrieveGroup,
        deleteGroup: deleteGroup,
        retrieveGroups: retrieveGroups,

        addGroupDevice: addGroupDevice,
        updateGroupDevice: updateGroupDevice,
        deleteGroupDevice: deleteGroupDevice,
        retrieveGroupDevice: retrieveGroupDevice,
        retrieveGroupDevices: retrieveGroupDevices,
        retrieveGroupsDevices: retrieveGroupsDevices,

        updateGroupDeviceAttributes: updateGroupDeviceAttributes,
        retrieveGroupDeviceAttributes: retrieveGroupDeviceAttributes,
        retrieveGroupGroupDeviceAttributes: retrieveGroupGroupDeviceAttributes,
        retrieveGroupsGroupDeviceAttributes: retrieveGroupsGroupDeviceAttributes,

        receiveDeviceStatuses: receiveDeviceStatuses,
        receiveGroupDeviceStatuses: receiveGroupDeviceStatuses,
        receiveOrganizationDeviceStatuses: receiveOrganizationDeviceStatuses
    };
};