import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
    calculateTime,
    round,
    truncateTimeToDay,
    calculateBalanceColor
} from "./common";
import {Link} from "react-router-dom";
import RichTable from "./RichTable";
import Tooltip from "@material-ui/core/Tooltip";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    panel: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    chartPanel: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 300,
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: "none",
    }
}));

export default function OrganizationDashboard({organizationId, since, until, groupsById, devicesByGroupId, groupDeviceAttributesByGroupId, deviceStatusesByDeviceId}) {
    const classes = useStyles();

    function calculateBalance(devicesById, groupDeviceAttributesByDeviceId, deviceStatusesByDeviceId, waterType) {
        function calculate(waterType) {
            let inState = 0;
            let outState = 0;
            let can = true;

            Array.from(devicesById.values()).forEach(device => {
                const groupDeviceAttributes = groupDeviceAttributesByDeviceId.get(device.id);
                const deviceStatuses = deviceStatusesByDeviceId.get(device.id) ? deviceStatusesByDeviceId.get(device.id).filter(status => status.isReading) : deviceStatusesByDeviceId.get(device.id);

                if (deviceStatuses && deviceStatuses.length > 1 && truncateTimeToDay(deviceStatuses[0].time).getTime() === truncateTimeToDay(until ? until : new Date()).getTime() && truncateTimeToDay(deviceStatuses[deviceStatuses.length - 1].time).getTime() === truncateTimeToDay(since).getTime()) {
                    if (groupDeviceAttributes.meter1BalanceWaterType === waterType && groupDeviceAttributes.meter1BalanceRole === "MAIN" && deviceStatuses[0].meter1Index) {
                        inState = inState + (deviceStatuses[0].meter1Index.value - deviceStatuses[deviceStatuses.length - 1].meter1Index.value) / 1000;
                    }
                    if (groupDeviceAttributes.meter2BalanceWaterType === waterType && groupDeviceAttributes.meter2BalanceRole === "MAIN" && deviceStatuses[0].meter2Index) {
                        inState = inState + ((deviceStatuses[0].meter2Index.value - deviceStatuses[deviceStatuses.length - 1].meter2Index.value) / 1000);
                    }

                    if (groupDeviceAttributes.meter1BalanceWaterType === waterType && groupDeviceAttributes.meter1BalanceRole === "SUBMETER" && deviceStatuses[0].meter1Index) {
                        outState = outState + ((deviceStatuses[0].meter1Index.value - deviceStatuses[deviceStatuses.length - 1].meter1Index.value) / 1000);
                    }
                    if (groupDeviceAttributes.meter2BalanceWaterType === waterType && groupDeviceAttributes.meter2BalanceRole === "SUBMETER" && deviceStatuses[0].meter2Index) {
                        outState = outState + ((deviceStatuses[0].meter2Index.value - deviceStatuses[deviceStatuses.length - 1].meter2Index.value) / 1000);
                    }

                } else {
                    can = false;
                }
            });

            return can ? ((outState - inState) / inState) * 100 : null;
        }

        return calculate(waterType);
    }

    const initialRows = (groupsById.size > 0 && devicesByGroupId.size > 0 && groupDeviceAttributesByGroupId.size > 0) ? (Array.from(groupsById.values()).map(group => {
        if (devicesByGroupId.get(group.id) && groupDeviceAttributesByGroupId.get(group.id)) {
            const devicesById = new Map(Array.from(devicesByGroupId.get(group.id)).map(value => [value.id, value]));
            const groupDeviceAttributesByDeviceId = new Map(Array.from(groupDeviceAttributesByGroupId.get(group.id)).map(value => [value.deviceId, value]));

            const balanceCold = round(calculateBalance(devicesById, groupDeviceAttributesByDeviceId, deviceStatusesByDeviceId, "COLD"), 2);
            const balanceHot = round(calculateBalance(devicesById, groupDeviceAttributesByDeviceId, deviceStatusesByDeviceId, "HOT"), 2);

            return {
                id: group.id,
                name: group.name,
                time: calculateTime(deviceStatusesByDeviceId),
                balanceCold: balanceCold,
                balanceHot: balanceHot,
            }
        } else {
            return null;
        }
    })).filter(value => value) : [];

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <RichTable name="Bilans" columns={
                        [
                            {
                                id: 'name',
                                numeric: false,
                                disablePadding: false,
                                label: 'Nazwa',
                                render: (row) => (<Link className={classes.link}
                                                        to={"/organizations/" + organizationId + "/groups/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.name}</Link>)
                            },
                            {
                                id: 'balanceCold',
                                numeric: true,
                                disablePadding: true,
                                label: 'ZW (%)',
                                render: (row) => (row.balanceCold === 1 / 0 ? "∞" : (row.balanceCold || row.balanceCold === 0) ?
                                    <span
                                        style={{color: (row.balanceCold || row.balanceCold === 0) ? calculateBalanceColor(row.balanceCold) : "black"}}>{row.balanceCold}</span> :
                                    <Tooltip title="Brak danych lub błędne ustawienia"><WarningIcon
                                        style={{color: "red"}}/></Tooltip>)
                            },
                            {
                                id: 'balanceHot',
                                numeric: true,
                                disablePadding: true,
                                label: 'CW (%)',
                                render: (row) => (row.balanceHot === 1 / 0 ? "∞" : (row.balanceHot || row.balanceHot === 0) ?
                                    <span
                                        style={{color: (row.balanceHot || row.balanceHot === 0) ? calculateBalanceColor(row.balanceHot) : "black"}}>{row.balanceHot}</span> :
                                    <Tooltip title="Brak danych lub błędne ustawienia"><WarningIcon
                                        style={{color: "red"}}/></Tooltip>)
                            }
                        ]
                    } initialRowsById={new Map(initialRows.map(group => {
                        return [group.id, {
                            id: group.id,
                            name: group.name,
                            balanceCold: group.balanceCold,
                            balanceHot: group.balanceHot
                        }];
                    }))} initialOrderBy="name"
                               initialOrder="asc"
                    />
                </Grid>
            </Grid>
        </Container>
    );
}