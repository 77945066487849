import * as moment from "moment";
import TimeRange from "./TimeRange";

export default function Device(id,
                               name,
                               description,
                               notificationMsisdns,
                               notificationEmailAddresses,
                               installationDistance,
                               alarmTimeSinceLastSeenLowThreshold,
                               alarmTimeSinceLastSeenHighThreshold,
                               alarmTimeSinceLastSeenNotificationMsisdns,
                               alarmTimeSinceLastSeenNotificationEmailAddresses,
                               alarmTemperatureLowThreshold,
                               alarmTemperatureHighThreshold,
                               alarmTemperatureNotificationMsisdns,
                               alarmTemperatureNotificationEmailAddresses,
                               alarmInput1TemperatureLowThreshold,
                               alarmInput1TemperatureHighThreshold,
                               alarmInput1TemperatureNotificationMsisdns,
                               alarmInput1TemperatureNotificationEmailAddresses,
                               alarmInput2TemperatureLowThreshold,
                               alarmInput2TemperatureHighThreshold,
                               alarmInput2TemperatureNotificationMsisdns,
                               alarmInput2TemperatureNotificationEmailAddresses,
                               alarmHumidityLowThreshold,
                               alarmHumidityHighThreshold,
                               alarmHumidityNotificationMsisdns,
                               alarmHumidityNotificationEmailAddresses,
                               lorawanDevEui,
                               lorawanNwkKey,
                               lorawanAppKey,
                               timeZoneOffset,
                               type) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.notificationMsisdns = notificationMsisdns;
    this.notificationEmailAddresses = notificationEmailAddresses;
    this.installationDistance = installationDistance;
    this.alarmTimeSinceLastSeenLowThreshold = alarmTimeSinceLastSeenLowThreshold;
    this.alarmTimeSinceLastSeenHighThreshold = alarmTimeSinceLastSeenHighThreshold;
    this.alarmTimeSinceLastSeenNotificationMsisdns = alarmTimeSinceLastSeenNotificationMsisdns;
    this.alarmTimeSinceLastSeenNotificationEmailAddresses = alarmTimeSinceLastSeenNotificationEmailAddresses;
    this.alarmTemperatureLowThreshold = alarmTemperatureLowThreshold;
    this.alarmTemperatureHighThreshold = alarmTemperatureHighThreshold;
    this.alarmTemperatureNotificationMsisdns = alarmTemperatureNotificationMsisdns;
    this.alarmTemperatureNotificationEmailAddresses = alarmTemperatureNotificationEmailAddresses;
    this.alarmInput1TemperatureLowThreshold = alarmInput1TemperatureLowThreshold;
    this.alarmInput1TemperatureHighThreshold = alarmInput1TemperatureHighThreshold;
    this.alarmInput1TemperatureNotificationMsisdns = alarmInput1TemperatureNotificationMsisdns;
    this.alarmInput1TemperatureNotificationEmailAddresses = alarmInput1TemperatureNotificationEmailAddresses;
    this.alarmInput2TemperatureLowThreshold = alarmInput2TemperatureLowThreshold;
    this.alarmInput2TemperatureHighThreshold = alarmInput2TemperatureHighThreshold;
    this.alarmInput2TemperatureNotificationMsisdns = alarmInput2TemperatureNotificationMsisdns;
    this.alarmInput2TemperatureNotificationEmailAddresses = alarmInput2TemperatureNotificationEmailAddresses;
    this.alarmHumidityLowThreshold = alarmHumidityLowThreshold;
    this.alarmHumidityHighThreshold = alarmHumidityHighThreshold;
    this.alarmHumidityNotificationMsisdns = alarmHumidityNotificationMsisdns;
    this.alarmHumidityNotificationEmailAddresses = alarmHumidityNotificationEmailAddresses;
    this.lorawanDevEui = lorawanDevEui;
    this.lorawanNwkKey = lorawanNwkKey;
    this.lorawanAppKey = lorawanAppKey;
    this.timeZoneOffset = timeZoneOffset;
    this.type = type;

    this.toJson = function() {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            notificationMsisdns: this.notificationMsisdns,
            notificationEmailAddresses: this.notificationEmailAddresses,
            installationDistance: this.installationDistance,
            alarmTimeSinceLastSeenLowThreshold: this.alarmTimeSinceLastSeenLowThreshold ? this.alarmTimeSinceLastSeenLowThreshold.toISOString() : null,
            alarmTimeSinceLastSeenHighThreshold: this.alarmTimeSinceLastSeenHighThreshold ? this.alarmTimeSinceLastSeenHighThreshold.toISOString() : null,
            alarmTimeSinceLastSeenNotificationMsisdns: this.alarmTimeSinceLastSeenNotificationMsisdns,
            alarmTimeSinceLastSeenNotificationEmailAddresses: this.alarmTimeSinceLastSeenNotificationEmailAddresses,
            alarmTemperatureLowThreshold: this.alarmTemperatureLowThreshold,
            alarmTemperatureHighThreshold: this.alarmTemperatureHighThreshold,
            alarmTemperatureNotificationMsisdns: this.alarmTemperatureNotificationMsisdns,
            alarmTemperatureNotificationEmailAddresses: this.alarmTemperatureNotificationEmailAddresses,
            alarmInput1TemperatureLowThreshold: this.alarmInput1TemperatureLowThreshold,
            alarmInput1TemperatureHighThreshold: this.alarmInput1TemperatureHighThreshold,
            alarmInput1TemperatureNotificationMsisdns: this.alarmInput1TemperatureNotificationMsisdns,
            alarmInput1TemperatureNotificationEmailAddresses: this.alarmInput1TemperatureNotificationEmailAddresses,
            alarmInput2TemperatureLowThreshold: this.alarmInput2TemperatureLowThreshold,
            alarmInput2TemperatureHighThreshold: this.alarmInput2TemperatureHighThreshold,
            alarmInput2TemperatureNotificationMsisdns: this.alarmInput2TemperatureNotificationMsisdns,
            alarmInput2TemperatureNotificationEmailAddresses: this.alarmInput2TemperatureNotificationEmailAddresses,
            alarmHumidityLowThreshold: this.alarmHumidityLowThreshold,
            alarmHumidityHighThreshold: this.alarmHumidityHighThreshold,
            alarmHumidityNotificationMsisdns: this.alarmHumidityNotificationMsisdns,
            alarmHumidityNotificationEmailAddresses: this.alarmHumidityNotificationEmailAddresses,
            lorawanDevEui: this.lorawanDevEui,
            lorawanNwkKey: this.lorawanNwkKey,
            lorawanAppKey: this.lorawanAppKey,
            timeZoneOffset: this.timeZoneOffset,
            type: this.type
        };
    }
}

Device.fromJson = function(json) {
    return new Device(
        json.id,
        json.name,
        json.description,
        json.notificationMsisdns,
        json.notificationEmailAddresses,
        json.installationDistance,
        json.alarmTimeSinceLastSeenLowThreshold ? moment.duration(json.alarmTimeSinceLastSeenLowThreshold) : null,
        json.alarmTimeSinceLastSeenHighThreshold ? moment.duration(json.alarmTimeSinceLastSeenHighThreshold) : null,
        json.alarmTimeSinceLastSeenNotificationMsisdns,
        json.alarmTimeSinceLastSeenNotificationEmailAddresses,
        json.alarmTemperatureLowThreshold,
        json.alarmTemperatureHighThreshold,
        json.alarmTemperatureNotificationMsisdns,
        json.alarmTemperatureNotificationEmailAddresses,
        json.alarmInput1TemperatureLowThreshold,
        json.alarmInput1TemperatureHighThreshold,
        json.alarmInput1TemperatureNotificationMsisdns,
        json.alarmInput1TemperatureNotificationEmailAddresses,
        json.alarmInput2TemperatureLowThreshold,
        json.alarmInput2TemperatureHighThreshold,
        json.alarmInput2TemperatureNotificationMsisdns,
        json.alarmInput2TemperatureNotificationEmailAddresses,
        json.alarmHumidityLowThreshold,
        json.alarmHumidityHighThreshold,
        json.alarmHumidityNotificationMsisdns,
        json.alarmHumidityNotificationEmailAddresses,
        json.lorawanDevEui,
        json.lorawanNwkKey,
        json.lorawanAppKey,
        json.timeZoneOffset,
        json.type);
};