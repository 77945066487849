import React from 'react';
import {useParams} from "react-router-dom";
import TabLayout from "./TabLayout";
import OrganizationGroups from "./OrganizationGroups";
import {groupBy} from "./common";
import OrganizationDashboard from "./OrganizationDashboard";

export default function OrganizationLayout({ds, since, until, admin, userGroups}) {
    const params = useParams();
    const organizationId = params.organizationId;

    const [groups, setGroups] = React.useState([]);
    const groupsById = new Map(groups.map(value => [value.id, value]))

    React.useEffect(() => {
        ds.retrieveGroups(organizationId,
            value => setGroups(value.filter(group => userGroups && userGroups.includes(group.id))));
    }, [organizationId]);

    function onDelete(selected) {
        selected.forEach(groupId =>
            ds.deleteGroup(organizationId, groupId, resp => {
                ds.retrieveGroups(organizationId,
                    groups => setGroups(groups))
            })
        );
    }

    const [devicesByGroupId, setDevicesByGroupId] = React.useState(new Map());

    React.useEffect(() => {
        if (groupsById.size > 0) {
            ds.retrieveGroupsDevices(Array.from(groupsById.keys()),
                devicesByGroup => setDevicesByGroupId(devicesByGroup))
        }
    }, [organizationId, groups]);

    const [groupDeviceAttributesByGroupId, setGroupDeviceAttributesByGroupId] = React.useState(new Map());

    React.useEffect(() => {
        if (groupsById.size > 0) {
            ds.retrieveGroupsGroupDeviceAttributes(Array.from(groupsById.keys()),
                groupDeviceAttributesByGroup => setGroupDeviceAttributesByGroupId(groupDeviceAttributesByGroup))
        }
    }, [organizationId, groups]);

    const [deviceStatuses, setDeviceStatuses] = React.useState([]);

    function calculateDeviceStatusesByDeviceId() {
        return new Map(Object.entries(groupBy(deviceStatuses, "deviceId"))
            .map(([deviceId, values]) => [deviceId, values.sort((a, b) => b.time - a.time)]));
    }

    const deviceStatusesByDeviceId = calculateDeviceStatusesByDeviceId();

    function truncateToDayStart(date) {
        if(date) {
            const updatedDate = new Date(date.getTime());
            updatedDate.setHours(0, 0, 0, 0);
            return updatedDate;
        } else {
            return date;
        }
    }

    function truncateToDayEnd(date) {
        if(date) {
            const updatedDate = new Date(date.getTime());
            updatedDate.setHours(23, 59, 59, 999);
            return updatedDate;
        } else {
            return date;
        }
    }

    React.useEffect(() => {
        const source1 = ds.receiveOrganizationDeviceStatuses(
            organizationId,
            truncateToDayStart(since),
            truncateToDayEnd(since));

        const source2 = ds.receiveOrganizationDeviceStatuses(
            organizationId,
            truncateToDayStart(until ? until : new Date()),
            truncateToDayEnd(until));

        let opening1 = true;
        source1.onMessages(incomingDeviceStatuses => {
            setDeviceStatuses(prevState => {
                let updated = opening1 ? [] : prevState.slice();
                opening1 = false;
                incomingDeviceStatuses.forEach(value => updated.push(value));
                return updated.sort((a, b) => b.time - a.time );
            });
        });

        let opening2 = true;
        source2.onMessages(incomingDeviceStatuses => {
            setDeviceStatuses(prevState => {
                let updated = opening2 ? [] : prevState.slice();
                opening2 = false;
                incomingDeviceStatuses.forEach(value => updated.push(value));
                return updated.sort((a, b) => b.time - a.time );
            });
        });


        return function cleanup() {
            source1.close();
            source2.close();
        };
    }, [organizationId, since, until]);

    return (
        <TabLayout tabs={
            [
                /*{
                    label: "Tablica",
                    name: "dashboard",
                    children: <OrganizationDashboard organizationId={organizationId} since={since} until={until} groupsById={groupsById} devicesByGroupId={devicesByGroupId} groupDeviceAttributesByGroupId={groupDeviceAttributesByGroupId} deviceStatusesByDeviceId={deviceStatusesByDeviceId}/>
                },*/
                {
                    label: "Instalacje",
                    name: "groups",
                    children: <OrganizationGroups organizationId={organizationId} since={since} until={until}
                                                  groups={groupsById} onDelete={onDelete} admin={admin}/>
                }
            ]
        }/>
    );
}