import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { calculateState, loraDataRateToValue } from "./common";
import RichTable from "./RichTable";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    }
}));

export default function DeviceReadings({ device, deviceStatuses }) {
    const classes = useStyles();

    const deviceTypeToFields = new Map([
        ["FILA_HEATING_COST_ALLOCATOR", new Set(["time", "recvTime", "seqNo", "temperature", "lorawanDr", "gwId", "rssi", "snr"])],
        ["TALKPOOL_OY1110", new Set(["time", "recvTime", "seqNo", "temperature", "humidity", "batteryVoltage", "lorawanDr", "gwId", "rssi", "snr"])],
        ["RAK_7204", new Set(["time", "recvTime", "seqNo", "temperature", "humidity", "batteryVoltage", "lorawanDr", "gwId", "rssi", "snr"])],
        ["DRAGINO_LHT65", new Set(["time", "recvTime", "seqNo", "temperature", "humidity", "batteryVoltage", "lorawanDr", "gwId", "rssi", "snr"])],
        ["DRAGINO_LGT92", new Set(["time", "recvTime", "seqNo", "position", "altitude", "batteryVoltage", "lorawanDr", "gwId", "rssi", "snr"])],
        ["ADEUNIS_TEMP_4", new Set(["time", "recvTime", "seqNo", "input1Temperature", "input2Temperature", "batteryVoltage", "lorawanDr", "gwId", "rssi", "snr"])],
        ["OCUPOLY_PARKING_SENSOR", new Set(["time", "recvTime", "seqNo", "occupancy", "distance", "batteryVoltage", "lorawanDr", "gwId", "rssi", "snr"])],
        ["OCUPOLY_PEOPLE_COUNTER", new Set(["time", "recvTime", "seqNo", "totalEntryCount", "totalExitCount", "batteryVoltage", "lorawanDr", "gwId", "rssi", "snr"])],
        ["TEKTELIC_SMART_ROOM_SENSOR", new Set(["time", "recvTime", "seqNo", "occupancy", "batteryVoltage", "lorawanDr", "gwId", "rssi", "snr"])]
    ]);

    function isSupported(name) {
        if (deviceTypeToFields.has(device.type)) {
            return deviceTypeToFields.get(device.type).has(name);
        } else {
            return true;
        }
    }

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <RichTable name="Odczyty" columns={
                        [
                            { id: 'time', numeric: false, disablePadding: false, label: 'Czas', visible: isSupported("time") },
                            { id: 'recvTime', numeric: false, disablePadding: false, label: 'Czas odbioru', visible: isSupported("recvTime") },
                            { id: 'seqNo', numeric: true, disablePadding: false, label: 'Numer sek.', visible: isSupported("seqNo") },
                            { id: 'temperature', numeric: true, disablePadding: false, label: 'Temperatura (°C)', visible: isSupported("temperature") },
                            {
                                id: 'input1Temperature',
                                numeric: true,
                                disablePadding: false,
                                label: 'Temperatura: wejście 1 (°C)',
                                visible: isSupported("input1Temperature")
                            },
                            {
                                id: 'input2Temperature',
                                numeric: true,
                                disablePadding: false,
                                label: 'Temperatura: wejście 2 (°C)',
                                visible: isSupported("input2Temperature")
                            },
                            { id: 'humidity', numeric: true, disablePadding: false, label: 'Wilgotność (%)', visible: isSupported("humidity") },
                            {
                                id: 'atmosphericPressure',
                                numeric: true,
                                disablePadding: false,
                                label: 'Ciśnienie atmosferyczne (Pa)',
                                visible: isSupported("atmosphericPressure")
                            },
                            {
                                id: 'totalEntryCount',
                                numeric: true,
                                disablePadding: false,
                                label: 'Całkowita liczba wejść',
                                visible: isSupported("totalEntryCount")
                            },
                            {
                                id: 'totalExitCount',
                                numeric: true,
                                disablePadding: false,
                                label: 'Całkowita liczba wyjść',
                                visible: isSupported("totalExitCount")
                            },
                            { id: 'occupancy', numeric: true, disablePadding: false, label: 'Zajętość', visible: isSupported("occupancy") },
                            {
                                id: 'totalOpenCount',
                                numeric: true,
                                disablePadding: false,
                                label: 'Całkowita liczba otwarć',
                                visible: isSupported("totalOpenCount")
                            },
                            {
                                id: 'totalCloseCount',
                                numeric: true,
                                disablePadding: false,
                                label: 'Całkowita liczba zamknięć',
                                visible: isSupported("totalCloseCount")
                            },
                            {
                                id: 'totalWaterLeakCount',
                                numeric: true,
                                disablePadding: false,
                                label: 'Całkowita liczba wycieków',
                                visible: isSupported("totalWaterLeakCount")
                            },
                            {
                                id: 'position',
                                numeric: true,
                                disablePadding: false,
                                label: 'Pozycja: dł.,szer. geogr. (°)',
                                visible: isSupported("position")
                            },
                            { id: 'altitude', numeric: true, disablePadding: false, label: 'Wysokość n.p.m. (m)', visible: isSupported("altitude") },
                            { id: 'distance', numeric: true, disablePadding: false, label: 'Odległość (m)', visible: isSupported("distance") },
                            { id: 'batteryVoltage', numeric: true, disablePadding: false, label: 'Napięcie baterii (V)', visible: isSupported("batteryVoltage") },
                            { id: 'lorawanDr', numeric: true, disablePadding: false, label: 'LoRaWAN DR', visible: isSupported("lorawanDr") },
                            { id: 'gwId', numeric: false, disablePadding: false, label: 'ID bramki', visible: isSupported("gwId") },
                            { id: 'rssi', numeric: true, disablePadding: false, label: 'RSSI (dBm)', visible: isSupported("rssi") },
                            { id: 'snr', numeric: true, disablePadding: false, label: 'SNR (dB)', visible: isSupported("snr") }
                        ]
                    } initialRowsById={new Map(deviceStatuses.filter(value => value.isReading).map(status => {
                        const id = moment(status.time).format("YYYY-MM-DD-HH-mm-ss");
                        return [id, {
                            //id: id,
                            time: moment(status.time).format("YYYY-MM-DD HH:mm:ss"),
                            recvTime: moment(status.rxMetadata.time).format("YYYY-MM-DD HH:mm:ss"),
                            seqNo: status.seqNo,
                            temperature: status.temperature ? status.temperature.value : null,
                            input1Temperature: status.input1Temperature ? status.input1Temperature.value : null,
                            input2Temperature: status.input2Temperature ? status.input2Temperature.value : null,
                            humidity: status.humidity ? status.humidity.value : null,
                            atmosphericPressure: status.atmosphericPressure ? status.atmosphericPressure.value : null,
                            totalEntryCount: status.totalEntryCount ? status.totalEntryCount.value : null,
                            totalExitCount: status.totalExitCount ? status.totalExitCount.value : null,
                            occupancy: status.occupancy ? status.occupancy.value : null,
                            totalOpenCount: status.totalOpenCount ? status.totalOpenCount.value : null,
                            totalCloseCount: status.totalCloseCount ? status.totalCloseCount.value : null,
                            totalWaterLeakCount: status.totalWaterLeakCount ? status.totalWaterLeakCount.value : null,
                            position: status.position ? status.position.value.lat + "," + status.position.value.long : null,
                            altitude: status.altitude ? status.altitude.value : null,
                            distance: status.distance ? status.distance.value : null,
                            batteryVoltage: status.batteryVoltage && status.batteryVoltage.time.getTime() === status.time.getTime() ? (status.batteryVoltage.value) : null,
                            lorawanDr: loraDataRateToValue(status.rxMetadata.dataRate),
                            gwId: status.rxMetadata.gwInfo[0].gwId,
                            rssi: status.rxMetadata.gwInfo[0].rssi,
                            snr: status.rxMetadata.gwInfo[0].snr
                        }];
                    }))} initialOrderBy="time"
                        initialOrder="desc"
                        downloadEnabled
                        selectionEnabled
                        filterEnabled
                        sortingEnabled
                    />
                </Grid>
            </Grid>
        </Container>
    );
}