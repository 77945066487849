import TimeRange from "./TimeRange";

export default function Group(id,
                              name,
                              notificationTimeRange,
                              notificationDaysOfWeek) {
    this.id = id;
    this.name = name;
    this.notificationTimeRange = notificationTimeRange;
    this.notificationDaysOfWeek = notificationDaysOfWeek;

    this.toJson = function () {
        return {
            id: this.id,
            name: this.name,
            notificationTimeRange: this.notificationTimeRange.toJson(),
            notificationDaysOfWeek: this.notificationDaysOfWeek
        };
    }
}

Group.fromJson = function (json) {
    return new Group(
        json.id,
        json.name,
        TimeRange.fromJson(json.notificationTimeRange),
        json.notificationTimeRange);
};