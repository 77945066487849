import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Title from './Title';
import LensIcon from '@material-ui/icons/Lens';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    typography: {
        fontSize: "0.875rem"
    },
    time: {
        color: theme.palette.text.secondary
    },
    iconCell: {
        padding: 0
    },
    noBottomBorder: {
        borderBottom: "none"
    }
}));

export default function AlarmPanel({title, alarms, time, color}) {
    const classes = useStyles();

    const rows = [
        {active: (alarms.meterConnected && alarms.meterConnected.value === true), name: "Odłączenie", time: moment((alarms.meterConnected ? alarms.meterConnected.time : time)).format("L LTS")},
        {active: (alarms.meterDisconnected && alarms.meterDisconnected.value === true), name: "Podłączenie", time: moment((alarms.meterDisconnected ? alarms.meterDisconnected.time : time)).format("L LTS")},
        {active: (alarms.meterDoseExceeded && alarms.meterDoseExceeded.value === true), name: "Przekroczenie dawki", time: moment((alarms.meterDoseExceeded ? alarms.meterDoseExceeded.time : time)).format("L LTS")},
        {active: (alarms.meterHoseCracked && alarms.meterHoseCracked.value === true), name: "Pęknięcie wężyka", time: moment((alarms.meterHoseCracked ? alarms.meterHoseCracked.time : time)).format("L LTS")},
        {active: (alarms.meterLeak && alarms.meterLeak.value === true), name: "Przeciek", time: moment((alarms.meterLeak ? alarms.meterLeak.time : time)).format("L LTS")},
        {active: (alarms.valveLeak && alarms.valveLeak.value === true), name: "Nieszczelność zaworu", time: moment((alarms.valveLeak ? alarms.valveLeak.time : time)).format("L LTS")},
        {active: (alarms.valveOpened && alarms.valveOpened.value === true), name: "Otwarcie zaworu", time: moment((alarms.valveOpened ? alarms.valveOpened.time : time)).format("L LTS")},
        {active: (alarms.valveClosed && alarms.valveClosed.value === true), name: "Zamknięcie zaworu", time: moment((alarms.valveClosed ? alarms.valveClosed.time : time)).format("L LTS")},
        {active: (alarms.batteryLow && alarms.batteryLow.value === true), name: "Słaba bateria", time: moment((alarms.batteryLow ? alarms.batteryLow.time : time)).format("L LTS")},
    ];

    return (
        <React.Fragment>
            <Title>{title}</Title>
            <Table size="small">
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell align="left" className={clsx(classes.iconCell, classes.noBottomBorder)}><LensIcon className={classes.icon} style={row.active ? {
                                color: color,
                                verticalAlign: "middle",
                                fontSize: "0.875rem"
                            } : {
                                color: "grey",
                                verticalAlign: "middle",
                                fontSize: "0.875rem"
                            }}/></TableCell>
                            <TableCell align="left" className={classes.noBottomBorder}>
                                {row.name}
                            </TableCell>
                            <TableCell align="right" className={clsx(classes.noBottomBorder, classes.time)} padding="none">{row.active ? row.time : null}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}