import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {loraDataRateToValue, chooseAlarm} from "./common";
import RichTable from "./RichTable";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    }
}));

export default function DeviceAlarms({device, deviceStatuses}) {
    const classes = useStyles();

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <RichTable name="Alarmy" columns={
                        [
                            {id: 'time', numeric: false, disablePadding: false, label: 'Czas'},
                            {id: 'recvTime', numeric: false, disablePadding: false, label: 'Czas odbioru'},
                            {id: 'seqNo', numeric: true, disablePadding: false, label: 'Numer sek.'},
                            {id: 'notifications', numeric: false, disablePadding: false, label: 'Powiadomienia'},
                            {id: 'batteryVoltage', numeric: true, disablePadding: false, label: 'Napięcie baterii (V)'},
                            {id: 'loraDr', numeric: true, disablePadding: false, label: 'LoRa DR'},
                            {id: 'gwId', numeric: false, disablePadding: false, label: 'ID bramki'},
                            {id: 'rssi', numeric: true, disablePadding: false, label: 'RSSI (dBm)'},
                            {id: 'snr', numeric: true, disablePadding: false, label: 'SNR (dB)'}
                        ]
                    } initialRowsById={new Map(deviceStatuses.filter(value => value.isAlarm).map(status => {
                        const id = moment(status.time).format("YYYY-MM-DD-HH-mm-ss");
                        return [id, {
                            id: id,
                            time: moment(status.time).format("YYYY-MM-DD HH:mm:ss"),
                            recvTime: moment(status.rxMetadata.time).format("YYYY-MM-DD HH:mm:ss"),
                            seqNo: status.seqNo,
                            notifications: [
                                chooseAlarm(device, status.time, status.meter1Connected, status.meter2Connected, "COLD", "Podłączenie ZW"),
                                chooseAlarm(device, status.time, status.meter1Connected, status.meter2Connected, "HOT", "Podłączenie CW"),
                                chooseAlarm(device, status.time, status.meter1Disconnected, status.meter2Disconnected, "COLD", "Odłączenie ZW"),
                                chooseAlarm(device, status.time, status.meter1Disconnected, status.meter2Disconnected, "HOT", "Odłączenie CW"),
                                chooseAlarm(device, status.time, status.meter1DoseExceeded, status.meter2DoseExceeded, "COLD", "Przekroczenie dawki ZW"),
                                chooseAlarm(device, status.time, status.meter1DoseExceeded, status.meter2DoseExceeded, "HOT", "Przekroczenie dawki CW"),
                                chooseAlarm(device, status.time, status.meter1HoseCracked, status.meter2HoseCracked, "COLD", "Pęknięcie wężyka ZW"),
                                chooseAlarm(device, status.time, status.meter1HoseCracked, status.meter2HoseCracked, "HOT", "Pęknięcie wężyka CW"),
                                chooseAlarm(device, status.time, status.meter1Leak, status.meter2Leak, "COLD", "Przeciek ZW"),
                                chooseAlarm(device, status.time, status.meter1Leak, status.meter2Leak, "HOT", "Przeciek CW"),
                                chooseAlarm(device, status.time, status.valve1Leak, status.valve2Leak, "COLD", "Nieszczelność zaworu ZW"),
                                chooseAlarm(device, status.time, status.valve1Leak, status.valve2Leak, "HOT", "Nieszczelność zaworu CW"),
                                chooseAlarm(device, status.time, status.valve1Opened, status.valve2Opened, "COLD", "Otwarcie zaworu ZW"),
                                chooseAlarm(device, status.time, status.valve1Opened, status.valve2Opened, "HOT", "Otwarcie zaworu CW"),
                                chooseAlarm(device, status.time, status.valve1Closed, status.valve2Closed, "COLD", "Zamknięcie zaworu ZW"),
                                chooseAlarm(device, status.time, status.valve1Closed, status.valve2Closed, "HOT", "Zamknięcie zaworu CW"),
                                status.batteryLow && status.batteryLow.time.getTime() === status.time.getTime() ? (status.batteryLow.value ? "Słaba bateria" : null) : null,
                            ].filter(value => value).join(", "),
                            batteryVoltage: status.batteryVoltage && status.batteryVoltage.time.getTime() === status.time.getTime() ? (status.batteryVoltage.value) : null,
                            loraDr: loraDataRateToValue(status.rxMetadata.dataRate),
                            gwId: status.rxMetadata.gwInfo[0].gwId,
                            rssi: status.rxMetadata.gwInfo[0].rssi,
                            snr: status.rxMetadata.gwInfo[0].snr,
                        }];
                    }))} initialOrderBy="time" initialOrder="desc"/>
                </Grid>
            </Grid>
        </Container>
    );
}